<template>
  <div class="overlay-loading" v-show="isWaitingForServer"></div>
  <div class="jackpot-actions">
    <h2>Select Jackpot game</h2>
    <ul class="list-buttons">
      <li v-for="game in games" v-bind:key="game.id">
        <button class="button" @click.stop="chooseGame(game)" :class="(chosenGame!=null && game.id==chosenGame.id?'disabled':'enabled')">{{ game.name }} - {{ game.cost }} SEK</button>
      </li>
    </ul>
    <div class="unfinished" v-if="unfinishedTickets.length > 0">
      <h2>Unfinished tickets</h2>
      <ul class="unfinished-list">
        <li v-for="ticket in unfinishedTickets" v-bind:key="ticket.correlationId">
          ID {{ ticket.correlationId }} <span v-if="ticket.tickets[0].autoCompleteOn">- AutoCompletes on: {{ $filters.formatDate(ticket.tickets[0].autoCompleteOn) }}</span> - <a href="#" @click.stop="finalizeTicket(ticket)">Finalize</a>
        </li>
      </ul>
    </div>
    <div class="ticket-buy" v-if="chosenGame != null">
      <button class="button" @click.stop="buy()">Buy ticket</button>
    </div>
    <div class="ticket-result" v-if="lastTicketResult">
      <h2>Ticket result</h2>
      <strong>Result:</strong> {{ lastTicketResult.winAmount }} SEK
    </div>
  </div>
</template>

<script>
import store from '../store.js';
//import axios from 'axios';
//import * as qs from 'qs'
import { updateBalance, buyGameCollection, getUnfinishedTickets, finalizeGameWithCorrelationId, getJackpotTicket } from '../api.js';

const { VUE_APP_GAMES_JACKPOT } = process.env;


export default {
  name: 'JackpotGame',
  props: {
  },
  mounted() {
    this.games = JSON.parse(VUE_APP_GAMES_JACKPOT);
  },
  data() {
    return {
      isWaitingForServer: false,
      chosenGame: null,
      store: store,
      currentCorrelationId: '',
      currentStatus: '',
      unfinishedTickets: [],
      lastTicketResult: null,
      games: []
    }
  },
  methods: {
    chooseGame(_game) {
      this.chosenGame = _game;
      this.checkUnfinishedTickets();
      this.lastTicketResult = null;
    },
    checkUnfinishedTickets() {
      getUnfinishedTickets(this.chosenGame.id).then((data) => {
        //console.log('unfinishedTickets: ', data);
        this.unfinishedTickets = data;
      }).catch(() => {
        this.unfinishedTickets = null;
      });
    },
    buy() {
      if (!this.chosenGame) return false;

      this.isWaitingForServer = true;
      let response = buyGameCollection(this.chosenGame.id).then((data) => {
        console.log('buy', this.chosenGame, data);
        console.dir(response);
        this.checkUnfinishedTickets();
        updateBalance();
        this.isWaitingForServer = false;
      }).catch(() => {
        this.isWaitingForServer = false;
      });
    },
    finalizeTicket(_ticket) {
      this.isWaitingForServer = true;

      finalizeGameWithCorrelationId(_ticket.correlationId).then((data) => {
//        let totalWin = 0;
//        let isWinner = false;

        data.tickets.forEach(ticket => {

          if (ticket.isWinner) { 
            getJackpotTicket(ticket.id).then((data) => {
//              totalWin += data.winAmount;
              ticket.winAmount = data.winAmount;
            });
          } else {
            ticket.winAmount = 0;
          }
          this.lastTicketResult = ticket;
        });
        this.isWaitingForServer = false;
        this.checkUnfinishedTickets();
        updateBalance();

      });
      /*
      _ticket.tickets.forEach(subticket => {
        finalizeGameTicket('PUT', 'jackpot/Tickets', subticket.id).then((data) => {
          console.log('Ticket finalized', subticket.id)
          this.lastTicketResult = data.object;
          this.checkUnfinishedTickets();
          updateBalance();
          this.isWaitingForServer = false;
        }).catch(() => {
          console.log('Ticket finalize failed')
          this.isWaitingForServer = false;
        });
      });
      */
    }
  }
}
</script>

<style scoped>

ul.list-buttons {
  list-style-type: none;
  padding: 0;
}
ul.list-buttons li {
  display: inline-block;
  margin: 0 10px 10px 0;
}
</style>
