import { createApp } from 'vue'
import App from './App.vue'

import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

const app = createApp(App);

app.config.globalProperties.$filters = {
  formatDate(value) {
    const d = new Date(value);
    return d.toLocaleString();
  }
}

app.use(mdiVue, {
  icons: mdijs
}).mount('#app')
